<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/deviceList' }">设备清单</el-breadcrumb-item>
          <el-breadcrumb-item>新增设备</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入设备名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备型号" prop="model">
                <el-input v-model="form.model" placeholder="请输入设备型号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="规格参数" prop="specification">
                <el-input v-model="form.specification" placeholder="请输入规格参数"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数量" prop="count">
                <el-input v-model="form.count" placeholder="请输入数量"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="生产厂家" prop="factory">
                <el-input v-model="form.factory" placeholder="请输入生产厂家"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="form-title">管理信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="所属部门">
                <el-select v-model="form.departmentId" placeholder="请选择所属部门">
                  <el-option label="无" value=""></el-option>
                  <el-option
                    v-for="item in bumen"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="负责人">
                <el-select v-model="form.staffId" placeholder="请选择负责人">
                  <el-option label="无" value=""></el-option>
                  <el-option
                    v-for="item in yuangong"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="设备分类">
                <el-select v-model="form.deviceTypeId" placeholder="请选择设备分类">
                  <el-option label="无" value=""></el-option>
                  <el-option
                    v-for="item in leixin"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="form-title">设备图片</div>
          <el-upload
            action="#"
            list-type="picture-card"
            :file-list="allFileList"
            :on-change="uploadChange"
            :auto-upload="false">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img class="el-upload-list__item-thumbnail" :src="file.url" />
              <span class="el-upload-list__item-actions">
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
          <div class="prompt">注意：图片尺寸800*800px，大小≤2MB，支持JPG、PNG、JPEG，最多上传5张</div>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/deviceList')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {},
      rules: {
        name:[{ required: true, message: '必填项', trigger: 'blur' }],
        specification:[{ required: true, message: '必填项', trigger: 'blur' }],
        factory:[{ required: true, message: '必填项', trigger: 'blur' }],
        count:[{ required: true, message: '必填项', trigger: 'blur' }],
        model:[{ required: true, message: '必填项', trigger: 'blur' }],
        deviceTypeId:[{ required: true, message: '必填项', trigger: 'blur' }],
        staffId:[{ required: true, message: '必填项', trigger: 'blur' }],
        departmentId:[{ required: true, message: '必填项', trigger: 'blur' }]
      },
      disabled: false,
      allFileList: [],
      bumen: [],
      leixin: [],
      yuangong: [],
    };
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("name", this.form.name);
          formData.append("model", this.form.model);
          formData.append("specification", this.form.specification);
          formData.append("factory", this.form.factory);
          formData.append("count", this.form.count);
          formData.append("deviceTypeId", this.form.deviceTypeId);
          // formData.append("facilityId", this.form.facilityId);
          formData.append("departmentId", this.form.departmentId);
          formData.append("staffId", this.form.staffId);
          formData.append("deviceStatusId", this.form.deviceStatusId);

          this.allFileList.forEach((item) => {
            formData.append("files", item.raw);
          });
          
          this.loading = true;
          this.$ajax.post("deviceCreate", formData).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/deviceList");
          }).catch(err=>{
            this.loading = false;
          })
        }
      })
    },
    uploadChange(file, fileList) {
      this.allFileList = fileList;
      console.log(fileList);
    },
    handleRemove(file) {
      function checkAdult(item) {
        return item.uid != file.uid;
      }
      this.allFileList = this.allFileList.filter(checkAdult);

      console.log(this.allFileList);
    },
    departmentList(){
      this.$ajax.get("departmentSecondLevel").then((res) => {
        this.bumen = res.data;
      })
    }
  },
  mounted() {
    if (this.$route.params == null) {
      this.$router.push("/deviceList");
    } else {
      let { leixin, yuangong } = this.$route.params;
      this.leixin = leixin;
      this.yuangong = yuangong;
      this.departmentList()
    }
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
}
.prompt {
  font-size: 14px;
  color: #9e9e9e;
  margin-top: 10px;
}
</style>